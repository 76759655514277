import EventBus from "./services/event-bus";
import MqSettings from "./settings/mq";
import Vue from 'vue';
const XF = {};
XF.Settings = {};

//Concept Code .. this EventBus should go into seperate package
//@todo Code should be nicer..  class/ namespaced..

XF._availableXelosPackages = [];

//is called from package to register with middleware
XF.registerXelosFrontendPackage = function(packageIdentifier){
  console.log(packageIdentifier);
  XF._availableXelosPackages.push(packageIdentifier);
}

XF.EventBus = EventBus;
XF.Settings.Mq = MqSettings;

//XF = Object.freeze(XF); todo test if this suits our case
window.XF = XF;

export default XF;